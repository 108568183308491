import React from 'react'
import { graphql } from 'gatsby'
import Home from '../../assets/img/home.jpg'

import { HomeBrands, HomeNewsLetter } from '../../components'

import ProduitsIrriglobemp4 from '../../assets/video/Irriglobe_Produits.mp4'
import ProduitsIrriglobewebm from '../../assets/video/Irriglobe_Produits.webm'

import {
  Container,
  Col,
  Row,
} from 'reactstrap'

import SEO from '../../components/seo'

const schemaOrg = `{
  "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Produit d'Irrigation",
  "provider": {
    "@type": "LocalBusiness",
    "name": "Irriglobe Irrigation",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Montréal",
      "addressRegion": "QC",
      "streetAddress": "8220 Pascal-Gagnon"
    },
    "description": "Installation de système d'irrigation. Installation de système d'arrosage automatique. Vente et réparation. Irriglobe, le spécialiste en irrigation!",
    "telephone": "514-905-6000"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Montreal"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laval"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Rive-Nord"
  },
  "areaServed": {
    "@type": "AdministrativeArea",
    "name": "Laurentides"
  },
  "hasOfferCatalog": {
    "@type": "OfferCatalog",
    "name": "Services Irrigation",
    "itemListElement": [
      {
        "@type": "OfferCatalog",
        "name": "Types services irrigation",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation résidentielle"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation commerciale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation municipale"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Irrigation Toîts vert"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Entretien annuel"
            }
          }
        ]
      }
    ]
  }
}`

class ProduitsIndex extends React.Component {
  render() {
    const seoData = {
      title: "Produits d'Irrigation et d'Arrosage Automatique",
      description:
        "Consultez la liste de nos produits d'Irrigation et d'Arrosage Automatique",
      cover: Home,
      path: '/produits',
      schemaOrg
    }

    return (
      <div>
        <SEO seoData={seoData} />
        <Container>
          <Row className="centered-container" style={{marginTop: 100, marginBottom: 50}}>
            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
              <h1>À propos de nos produits</h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
               <video controls style={{ width: '100%' }}>
                <track default kind="captions"
                  srcLang="fr"
                  src="" />
                <source src={ProduitsIrriglobewebm} type="video/webm" />
                <source src={ProduitsIrriglobemp4} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
        <HomeBrands />
        <HomeNewsLetter/>
      </div>
    )
  }
}

export default ProduitsIndex

export const produitsQuery = graphql`
  query produitsQuery {
    site {
      siteMetadata {
        title
        description
        url: siteUrl
        author
      }
    }
  }
`

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import config from '../../../gatsby-config'

class SEO extends Component {
  render() {
    /*
    * @param seoData: title, description, coverImage, path
    */
    const { seoData } = this.props
    let title
    let description
    let coverImage
    let pageURL
    let schemaOrg
    if (seoData) {
      title = seoData.title
      description = seoData.description
      coverImage = seoData.coverImage
      pageURL = config.siteMetadata.siteUrl + seoData.path
      schemaOrg = seoData.schemaOrg
    } else {
      title = config.siteMetadata.title
      description = config.siteMetadata.Description
      coverImage = config.siteMetadata.logo
      schemaOrg = []
    }
    return (
      <Helmet>
        {/* General tags */}
        <title>
          {title} | {config.siteMetadata.title}
        </title>
        <meta name="description" content={description} />
        <meta name="image" content={coverImage} />
        <link rel="canonical" href={pageURL} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {schemaOrg}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:url" content={pageURL} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={coverImage} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={config.userTwitter ? config.userTwitter : ''}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={coverImage} />
      </Helmet>
    )
  }
}

export default SEO
